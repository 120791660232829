import React, { useState } from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import {
  EmailShareButton,
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
} from 'react-share';
import { useLocation } from '@reach/router';
import { Link } from 'gatsby';
import { motion } from 'framer-motion';

import { QueryFragments } from '@querys/queryFragments'; // eslint-disable-line

// Components
import { PageMeta } from '@components/pageMeta';
import Layout from '@components/layout';
import { PortableTextBlock } from '@modules/portableTextBlock';
import { GetBlogs } from '@querys/getBlogs';

import { Arrow } from '@svg/arrow';

const arrowVars = {
  initial: {
    x: -20,
  },
  animate: {
    x: 0,
    transition: {
      duration: 0.3,
    },
  },
};

const Intro = (blog) => {
  const { category, date, title, excerpt, blogColor, featuredImage } =
    blog || {};

  return (
    <div
      style={{ backgroundColor: `${blogColor.hex}` }}
      className='grid grid-cols-1 lg:grid-cols-2 gap-x-24 pt-32 lg:pt-48 pb-8 lg:pb-32 px-gutter'>
      <div className='col-span-1 flex flex-col justify-center'>
        <p
          className={`text-sm leading-sm lg:text-xl lg:leading-xl text-[#00000080]`}>
          {category} - {date}
        </p>
        <h1 className='mt-4 text-2xl leading-2xl md:text-5xl md:leading-5xl font-regular'>
          {title}
        </h1>
        {excerpt && (
          <p className='text-sm leading-sm md:text-3xl md:leading-3xl mt-4'>
            {excerpt}
          </p>
        )}
      </div>

      <div className='col-span-1 mt-12 lg:mt-0'>
        <Image {...featuredImage.image} />
      </div>
    </div>
  );
};
const Content = (blog) => {
  const { content } = blog || {};
  const { href } = useLocation();

  return (
    <div className='grid grid-cols-14 pt-12 lg:pt-32 prose md:prose-lg text-black'>
      <div className='col-start-2 col-end-14 lg:col-start-4 lg:col-end-12'>
        {content && <PortableTextBlock text={content} />}
      </div>

      <div className='mt-10 lg:mt-20 col-start-2 col-end-14 lg:col-start-4 lg:col-end-12'>
        <p>Share article:</p>
        <div className='lg:-mt-2'>
          <FacebookShareButton url={href} className='block underline -mt-1'>
            <p>Facebook</p>
          </FacebookShareButton>

          <TwitterShareButton
            url={href}
            className='block underline -mt-3 lg:-mt-6'>
            <p>X</p>
          </TwitterShareButton>

          <EmailShareButton
            url={href}
            className='block underline -mt-3 lg:-mt-6'>
            <p>Email</p>
          </EmailShareButton>
        </div>
      </div>
    </div>
  );
};
const OtherBlogs = ({ currentSlug }) => {
  const others = GetBlogs();
  let count = 0;

  const [otherHover, setOtherHover] = useState(false);

  return (
    <div className='pt-12 pb-24 lg:pt-48 lg:pb-48 px-gutter'>
      <h3 className='text-3xl leading-3xl md:text-5xl md:leading-5xl font-medium'>
        Other News & Reviews
      </h3>
      <div className='mt-8 lg:mt-14 grid grid-cols-1 lg:grid-cols-3 gap-x-16 gap-y-8'>
        {others.map((blog, i) => {
          const { slug, featuredImage, title, category, external } = blog || {};
          if (slug.current !== currentSlug.current && count < 3) {
            count++;
            return (
              <div key={i} className='col-span-1'>
                <div className='overflow-hidden'>
                  {external ? (
                    <a
                      onMouseEnter={() => setOtherHover(i)}
                      onMouseLeave={() => setOtherHover(false)}
                      href={external}
                      target='_blank'
                      rel='noreferrer'>
                      <Image
                        className='transform hover:scale-105 duration-300 ease-in-out'
                        {...featuredImage.image}
                      />
                    </a>
                  ) : (
                    <Link
                      onMouseEnter={() => setOtherHover(i)}
                      onMouseLeave={() => setOtherHover(false)}
                      to={`/news/${slug.current}`}>
                      <Image
                        className='transform hover:scale-105 duration-300 ease-in-out'
                        {...featuredImage.image}
                      />
                    </Link>
                  )}
                </div>
                <p className='mt-4 text-grey text-sm leading-sm md:text-base md:leading-base'>
                  {category}
                </p>
                <h4 className='text-xl leading-xl font-medium md:text-3xl md:leading-3xl'>
                  {title}
                </h4>
                <div className='mt-2'>
                  {external ? (
                    <a
                      onMouseEnter={() => setOtherHover(i)}
                      onMouseLeave={() => setOtherHover(false)}
                      href={external}
                      target='_blank'
                      rel='noreffer'
                      className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg w-full flex flex-row justify-between items-center'>
                      Read article
                      <motion.span
                        variants={arrowVars}
                        initial='initial'
                        animate={otherHover === i ? 'animate' : 'initial'}>
                        <Arrow white={false} />
                      </motion.span>
                    </a>
                  ) : (
                    <Link
                      onMouseEnter={() => setOtherHover(i)}
                      onMouseLeave={() => setOtherHover(false)}
                      to={`/news/${slug.current}`}
                      className='font-semibold mt-0-important text-sm leading-sm md:text-lg md:leading-lg w-full flex flex-row justify-between items-center'>
                      Read article
                      <motion.span
                        variants={arrowVars}
                        initial='initial'
                        animate={otherHover === i ? 'animate' : 'initial'}>
                        <Arrow white={false} />
                      </motion.span>
                    </Link>
                  )}
                </div>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const BlogTemplate = ({ data: { blog } }) => {
  const { meta, settings } = blog || {};
  return (
    <Layout settings={settings}>
      <PageMeta {...meta} />
      <Intro {...blog} />
      <Content {...blog} />
      <OtherBlogs currentSlug={blog.slug} />
    </Layout>
  );
};

export default BlogTemplate;

export const blogQuery = graphql`
  query standardBlogQuery($slug: String!) {
    blog: sanityBlog(slug: { current: { eq: $slug } }) {
      meta {
        ...MetaCardFields
      }
      content: _rawContent(resolveReferences: { maxDepth: 100 })
      blogColor {
        hex
      }
      date(locale: "NZ", formatString: "DD MMM YYYY")
      excerpt
      category
      slug {
        current
      }
      featuredImage {
        image {
          ...ImageWithPreview
        }
      }
      title
    }
  }
`;
